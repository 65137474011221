import {Injectable} from '@angular/core';
import {Observable, of} from "rxjs";
import {Payload} from "@cat2/legacy-meta-cat/lib/shared/metadata/payload";
import {FlockManagementService} from "@core/services/flock-management.service";
import {FarmMastRecord, House} from "@core/services/flock-management.swagger";
import {SortingService} from "@core/services/sorting.service";
import {SourcesPipe} from "@core/pipes/sources.pipe";

@Injectable({
  providedIn: 'root'
})
export class FlocksGridService {
  status = "";
  sourcesPipe = new SourcesPipe();

  constructor(
    private _flockManagementService: FlockManagementService,
    private _sortingService: SortingService
  ) {
    this._flockManagementService.flocksStatusFilter.subscribe((status: string) => {
      this.status = status;
    });
  }

  readAllRecords(): Observable<Payload<any>> {
    const executionStart = new Date();
    let flocks: any[];
    switch (this._flockManagementService.activeView) {
      case 'farm':
        flocks = this.loadFarmFlocks();
        break;
      case 'house':
        flocks = this.loadHouseFlocks();
        break;
      default:
        flocks = this.loadAllFlocks();
    }
    return of(this.toPayload(flocks.filter(f => this.status === "all" ? true : f.status === this.status), executionStart));
  }

  loadFarmFlocks() {
    if (this._flockManagementService.flocksStatusFilter.value !== 'all') {
      this._flockManagementService.handleFlockFilterStatusChange('all');
    }
    return (this._flockManagementService.flocks || [])
      .filter((flock) =>
        this._sortingService.sort((this._flockManagementService.houses || []).filter(
          (house) =>
            house.farmNo ===
            (this._flockManagementService.activeItem! as FarmMastRecord)
              .farmNo &&
            house.growerReference ===
            (this._flockManagementService.activeItem! as FarmMastRecord)
              .growerReference
        )).map(elem => elem.guid).some(guid => flock.houses?.includes(guid))
      )
      .map((flock) => {
        return {
          ...flock,
          flockHouses: this._flockManagementService.houses
            ?.filter((house) => flock.houses?.includes(house.guid))
            .map((house) => house.houseName),
        };
      });
  }

  loadHouseFlocks() {
    return (this._flockManagementService.flocks || [])
      .filter((flock) =>
        flock.houses?.includes(
          (this._flockManagementService.activeItem! as House).guid
        )
      )
      .map((flock) => {
        return {
          ...flock,
          flockHouses: Array.from(new Set(this._flockManagementService.houses
            ?.filter((house) => flock.houses?.includes(house.guid))
            .map((house) => house.houseName))),
        };
      });
  }

  loadAllFlocks() {
    return (this._flockManagementService.flocks || [])
      .map((flock) => {
        return {
          ...flock,
          flockHouses: this._flockManagementService.houses
            ?.filter((house) => flock.houses?.includes(house.guid))
            .map((house) => house.houseName),
        };
      });
  }

  readRecord(id: string): Observable<Payload<any>> {
    console.log('readRecord')
    return of({} as Payload<any>);
  }

  updateRecord(id: string, userInput: any): Observable<Payload<any>> {
    console.log('updateRecord: ' + id);
    console.log(userInput);
    return of({} as Payload<any>);
  }

  createClass(item: any): Observable<Payload<any>> {
    console.log('createClass: ' + item)
    return of({} as Payload<any>);
  }

  deleteRecord(id: string): Observable<Payload<boolean>> {
    console.log('deleteRecord: ' + id)
    return of({} as Payload<any>);
  }

  private toPayload(items: any, executionStart: Date, errorMsg?: string): Payload<any> {
    return {
      data: Array.isArray(items) ? items.map(i => this.parseItems(i)) : this.parseItems(items),
      txGuid: '',
      executionStart: executionStart,
      executionEnd: new Date(),
      statusMessage: errorMsg ? errorMsg : 'ok',
      status: errorMsg ? 2 : 0,
    }
  }

  private parseItems(item: any) {
    if (item && typeof item != "boolean") {
      return {
        ...item,
        __id: item.guid,
        flockSourceDisplay: this.sourcesPipe.transform(item.flockSource),
        classificationDisplay: this._flockManagementService.classifications?.find(c=> c.classification === item.classification)?.description
      }
    }
    return item;
  }
}
